import { SwiperSlide, Swiper, useSwiper } from "swiper/react";
import React, {  useState } from "react";
import Text from "../atoms/Text";
import Title from "../atoms/Title";
import restaurant from "@/images/icons/restaurante-blue.svg";
import bar from "@/images/icons/bar-blue.svg";
import sport from "@/images/icons/actividades-deportivas-blue.svg";
import eDay from "@/images/icons/entretenimiento-dia-blue.svg";
import show from "@/images/icons/show-blue.svg";
import pool from "@/images/icons/pool-blue.svg";
import jacuzzi from "@/images/icons/jacuzzi-blue.svg";
import seaService from "@/images/icons/sea-service-blue.svg";
import wellness from "@/images/icons/wellness-blue.svg";
import tennis from "@/images/icons/tennis-blue.svg";
import parking from "@/images/icons/parking-blue.svg";
import roomservice from "@/images/icons/roomservice-blue.svg";
import Image from "next/image";
import { Autoplay, Navigation, Pagination, EffectFade, Virtual } from "swiper";
import prev from "@/public/images/icons/prev.svg";
import next from "@/public/images/icons/next.svg";
import { useTranslation } from "next-i18next";

import classNames from "classnames";
const Includes = ({ copy = {} }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiper = useSwiper();
  const includes = [
    {
      image: (
        <Image
          src={restaurant}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item1?.title,
      text: copy?.items?.item1?.text,
    },
    {
      image: (
        <Image
          src={bar}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item2?.title,
      text: copy?.items?.item2?.text,
    },
    {
      image: (
        <Image
          src={sport}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item3?.title,
      text: copy?.items?.item3?.text,
    },
    {
      image: (
        <Image
          src={eDay}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item4?.title,
      text: copy?.items?.item4?.text,
    },
    {
      image: (
        <Image
          src={show}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item5?.title,
      text: copy?.items?.item5?.text,
    },
    {
      image: (
        <Image
          src={pool}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item6?.title,
      text: copy?.items?.item6?.text,
    },
    {
      image: (
        <Image
          src={jacuzzi}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item7?.title,
      text: copy?.items?.item7?.text,
    },
    {
      image: (
        <Image
          src={seaService}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item8?.title,
      text: copy?.items?.item8?.text,
    },
    {
      image: (
        <Image
          src={wellness}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item9?.title,
      text: copy?.items?.item9?.text,
    },
    {
      image: (
        <Image
          src={tennis}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item10?.title,
      text: copy?.items?.item10?.text,
    },
    {
      image: (
        <Image
          src={parking}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item12?.title,
      text: copy?.items?.item12?.text,
    },
    {
      image: (
        <Image
          src={roomservice}
          alt="includeicon"
          className="mb-1 lg:mb-4 mx-auto lg:ml-0 lg:mr-auto"
        />
      ),
      name: copy?.items?.item13?.title,
      text: copy?.items?.item13?.text,
    },
  ];
  const slice1 = includes.slice(0, 6);
  const slice2 = includes.slice(6, 12);
  const slicedIncludes = [slice1, slice2];

  const settings = {
    slidesPerView: 1,
    navigation: {
      nextEl: ".next",
      prevEl: ".prev",
    },
    pagination: {
      clickable: true,
    },
    modules: [Autoplay, Navigation, Pagination, EffectFade, Virtual],
    style: {
      "--swiper-navigation-size": "20px",
      "--swiper-theme-color": "#00205C",
    },
    autoplay: {
      delay: 10000,
    },
    virtual: {
      enabled: true,
    },
    speed: 1500,
    onSlideChange: (swiper) => {
      setCurrentSlide(swiper.activeIndex);
    },
  };
  const { t } = useTranslation(["common"]);
  return (
    <section className="base-container base-grid">
      <div className="col-span-12 lg:col-span-10 lg:col-start-2 ">
        <div className="grid grid-cols-10 py-12 bg-white  lg:gap-x-8">
          <div className="col-span-10 lg:col-span-4 relative lg:min-h-[520px]">
            <Text className="section-subtitle text-center lg:text-left uppercase">
              {copy?.subtitle}
            </Text>
            <Title
              tag="h2"
              className="section-title block pt-4 normal-case tracking-normal text-5xl pb-8 text-center lg:text-left"
            >
              {copy?.title}
            </Title>
            <Text className="hero-text text-center lg:text-left lg:mb-20">
              {copy?.text}
            </Text>
            <div className="absolute bottom-0 hidden grid-cols-2 w-full lg:grid">
              <Text className="text-5xl font-medium font-poppins text-main-blue-king">
                {currentSlide + 1}
                <span className="font-light ml-2">/</span>
                <span className="font-light text-3xl ml-1">
                  {slicedIncludes.length}
                </span>
              </Text>
              <div className="flex justify-between col-start-2">
                <Image
                  className={classNames(
                    currentSlide === 0 && "opacity-50",
                    "prev cursor-pointer"
                  )}
                  src={prev}
                  width={12}
                  height={22}
                  alt={t('common:icons.left-arrow.al')}
                />
                <Image
                  className={classNames(
                    currentSlide + 1 === slicedIncludes.length && "opacity-50",
                    "next cursor-pointer"
                  )}
                  src={next}
                  width={12}
                  height={22}
                  alt={t('common:icons.right-arrow.al')}
                />
              </div>
            </div>
          </div>
          <div className="col-span-10 lg:col-span-6">
            <Swiper {...settings} className="hidden-pagination">
              {slicedIncludes.map((slice, key) => {
                return (
                  <SwiperSlide key={"slide-" + key + 1}>
                    <div className="h-full base-grid py-10">
                      {slice.map((item, key) => {
                        return (
                          <div key={key} className="col-span-6">
                            {item.image}
                            <Text className="font-title text-main-blue text-lg mb-1 lg:mb-4 text-center lg:text-left min-h-[auto] lg:min-h-min">
                              {item.name}
                            </Text>
                            <Text className="font-text text-main-grey text-sm text-center lg:text-left">
                              {item.text}
                            </Text>
                          </div>
                        );
                      })}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Includes;
