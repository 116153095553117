import Head from "next/head";
import NavBar from "@/components/templates/NavBar";
import Slider from "@/components/templates/Slider";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import Slideshow from "@/components/templates/Slideshow";
import TabsWithFilter from "@/components/templates/TabsWithFilter";
import PromotionsCarousel from "@/components/templates/PromotionsCarousel";
import Welcome from "@/components/templates/Welcome";
import Hero from "@/components/organisms/Hero";
import welcomeImage from "@/images/home/welcome/welcome.webp";
import barinactive from "@/public/images/icons/bar-blue-tab.svg";
import baractive from "@/public/images/icons/bar-white.svg";
import restaurantinactive from "@/public/images/icons/restaurant-blue-tab.svg";
import restaurantactive from "@/public/images/icons/restaurant-white.svg";
import Footer from "@/components/templates/Footer";
import Newsletter from "@/components/templates/Newsletter";
import Booking from "@/components/templates/Booking";
import Reviews from "@/components/templates/Reviews";
import Instagram from "@/components/templates/Instagram";
import BlogsHome from "@/components/templates/BlogsHome";
import Includes from "@/components/templates/Includes";
import HappeningsList from "@/components/templates/HappeningsList";
import {
  apiSliders,
  apiUrl,
  apiBlogs,
  apiPromotions,
  apiRoomCategories,
  apiRooms,
  axiosGet,
  apiHappenings,
} from "@/utils/api";
import RoomTab from "@/components/templates/RoomTab";
import QueryString from "qs";
import moment from 'moment'
const currentDate = moment(new Date()).format('YYYY-MM-DD,  HH:MM:SS');

const contentForTabsRestaurant = (items, link) => {
  return items && Array.isArray(items)
    ? items.map((item) => {
        return {
          id: item?.id,
          tab: item?.tab,
          paragraphs: [
            {
              text: item?.pretitle,
              classes:
                "font-subtitle text-xs tracking-[0.2em] text-main-orange text-center md:text-left",
            },
            {
              text: item?.title,
              classes:
                "font-title text-2xl md:text-3xl pb-2 text-white text-center md:text-left",
            },
            {
              text: item?.text,
              classes:
                "font-text text-sm leading-7 pb-7 text-white text-center md:text-left",
            },
            {
              text: link,
              type: "link",
              href: "/restaurants/" + item?.slug,
              classes:
                "underline font-subtitle text-base cursor-pointer hover:opacity-75 text-main-orange block mx-auto w-fit md:ml-0",
            },
            {
              text: item?.discover_menu,
              type: "link",
              href: item?.menu,
              target: "_blanck",
              classes: `underline font-subtitle text-base cursor-pointer hover:opacity-75 text-main-orange block mx-auto w-fit md:ml-0 ${
                item.menu === undefined ? "hidden" : null
              }`,
              seeMenuId: item?.seeMenuId,
            },
          ],
          images: item?.images,
        };
      })
    : [];
};

export default function Home(props) {
  const {
    mainSliders = [],
    rooms = [],
    roomCategories = [],
    promotions = [],
    blogs = [],
    happenings = [],
    locale = "es",
  } = props;
  // console.log(mainSliders)
  const { noIndex } = props;
  const { t } = useTranslation("common", "home");
  // const sliders = t("home:sliders", { returnObjects: true });
  const sliders =
    mainSliders
      ?.filter((s) => s.image)
      ?.map((s) => {
        if (s.mobile_image) {
          return {
            title: s.title,
            subtitle: s.subtitle,
            url: s.url,
            image_mobile: {
              url: apiUrl + s.mobile_image.url,
              width: s.mobile_image.width,
              height: s.mobile_image.height,
              alt: s.mobile_image_alt || "",
            },
            image: {
              url: apiUrl + s.image.url,
              width: s.image.width,
              height: s.image.height,
              alt: s.image_alt || "",
            },
          };
        } else {
          return {
            title: s.title,
            subtitle: s.subtitle,
            url: s.url,
            image: {
              url: apiUrl + s.image.url,
              width: s.image.width,
              height: s.image.height,
              alt: s.image_alt || "",
            },
          };
        }
      }) || t("home:sliders", { returnObjects: true });

  const contentForTabsRestaurant = (items, link) => {
    return items && Array.isArray(items)
      ? items.map((item) => {
          return {
            id: item?.id,
            tab: item?.tab,
            paragraphs: [
              {
                text: item?.pretitle,
                classes:
                  "font-subtitle text-xs tracking-[0.2em] text-main-orange text-center md:text-left",
              },
              {
                text: item?.title,
                classes:
                  "font-title text-2xl md:text-3xl pb-2 text-white text-center md:text-left",
              },
              {
                text: item?.text,
                classes:
                  "font-text text-sm leading-7 pb-7 text-white text-center md:text-left",
              },
              {
                text: link,
                type: "link",
                href: t("routes:/dining") + "/" + item?.slug,
                accesibleName: t(
                  "common:accesible_names.restaurants_bars.discover_more"
                ),
                classes:
                  "underline font-subtitle text-base cursor-pointer hover:opacity-75 text-main-orange mx-auto w-fit mb-2 lg:mb-0 block lg:inline",
              },
              {
                text: item?.discover_menu,
                type: "link",
                href: item?.menu,
                target: "_blanck",
                classes: `underline font-subtitle text-base cursor-pointer hover:opacity-75 text-main-orange mx-auto w-fit lg:ml-8 ${
                  item?.menu === undefined ? "hidden" : null} block lg:inline`,
                seeMenuId: item?.seeMenuId,
              },
            ],
            images: item?.images,
          };
        })
      : [];
  };

  const home = t("home:landing", { returnObjects: true });
  let restaurants = t("home:restaurants.tabs", { returnObjects: true });
  let bars = t("home:bars.tabs", { returnObjects: true });
  restaurants = contentForTabsRestaurant(
    restaurants,
    t("home:restaurants.discover-more")
  );
  bars = contentForTabsRestaurant(
    bars,
    t("home:restaurants.discover-more")
  );
  const filters = [
    {
      tab: t("home:landing.restaurants.restaurants"),
      icon: restaurantinactive,
      iconActive: restaurantactive,
      tabs: restaurants,
    },
    {
      tab: t("home:landing.restaurants.bars"),
      icon: barinactive,
      iconActive: baractive,
      tabs: bars,
    },
  ];
  const filterstyle = {
    filters:{
      active: "mx-2 sm:mx-8 py-2 px-2 sm:px-8 cursor-pointer flex flex-column items-center rounded-full bg-main-blue text-white",
      inactive: "mx-2 sm:mx-8 py-2 px-2 sm:px-8 cursor-pointer flex flex-column items-center rounded-full bg-medium-grey text-main-blue",
      tab: "font-subtitle text-lg uppercase px-3 ",
    },
    tabs:{
      tabContainer: "flex justify-center gap-3 items-center flex-wrap",
      active: " text-main-blue bg-white font-medium py-2 px-4 rounded-full",
      inactive:"text-white bg-main-blue-king/[0.50] font-medium py-2 px-4 rounded-full",
      tab: "font-subtitle text-sm uppercase",
      subtitle: "font-subtitle text-xs opacity-75",
    },
    dropdown: "font-subtitle  flex justify-around gap-3 items-center cursor-pointer px-3 py-3 rounded-full border border-main-blue bg-main-blue-king"
  };
  const bookTour = t("home:bookTour", { returnObjects: true });
  const viewActivities = t("home:viewActivities", { returnObjects: true });
  const metas = t("home:metas", { returnObjects: true });
  const welcomeImg = {
    alt:
      locale === "es"
        ? "vista-del-mar-de-dia-en-cancun-con-palapas-y-palmeras"
        : "daytime-sea-view-in-Cancun-with-palapas-and-Palm-trees",
    ...welcomeImage,
  };

  const slidesShow = t("home:landing.slidesShow", { returnObjects: true });

  return (
    <>
      <Head>
        <title>{metas?.title}</title>
        <meta name="description" content={metas?.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {noIndex && <meta name="robots" content="noindex" />}
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href={t('common:canonical_pages.home')} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(t("home:schema", { returnObjects: true })),
          }}
        />
      </Head>
      <main>
        <NavBar />
        <Booking />
        <Slider sliders={sliders} />
        {/* <Widget /> */}
        <Welcome
          title={{
            text: (
              <>
                {home?.welcome?.title1}
                <span className="block text-lg md:text-4xl md:pt-2">
                  {home?.welcome?.title2}
                </span>
              </>
            ),
            tag: "h2",
          }}
          secondTitle={{
            text: home?.welcome?.pretitle,
            className: "section-subtitle text-center uppercase",
            tag: "h1",
          }}
          paragraphs={[
            {
              text: home?.welcome?.text1,
              classes: "hero-text mb-4",
            },
            {
              text: home?.welcome?.text2,
              classes: "hero-text mb-4",
            },
            {
              text: home?.welcome?.text3,
              classes: "hero-text font-semibold text-lg",
            },
          ]}
          image={welcomeImg}
          styles={{
            titleContainer: "order-2 col-span-12 md:col-start-2 md:col-span-10",
            textContainer: "order-1 col-span-12 md:col-start-2 md:col-span-10",
            paragraphsContainer:
              "col-span-12 order-2 md:order-1 md:col-start-2 md:col-span-6 md:py-6 lg:py-12",
            image: "w-full",
            imageContainer: "col-span-12 md:col-span-4 md:order-2",
            topParagraphsContainer: "hidden",
            bottomImageContainer: "hidden",
          }}
        />
        <Slideshow images={slidesShow} />
        <RoomTab
          rooms={rooms}
          categories={roomCategories}
          copy={{ ...home?.rooms, locale }}
        />
        <PromotionsCarousel promotions={promotions} />

        <Includes copy={home?.includes} />
        <Reviews
          title={{
            text: t("common:reviewsTitle"),
          }}
          text={{
            text: t("common:reviewsText"),
          }}
          reviews={t("common:reviews", { returnObjects: true })}
        />
        <HappeningsList
          locale={locale}
          happenings={happenings}
          happeningsUrl={t("routes:/activities")}
          titleSection={t("common:titleSectionHappenings")}
          text={{
            text: t("common:reviewsText"),
          }}
          calendarText={t("common:calendarText")}
          moreHappeningsText={t("common:moreHappeningsText")}
        />
        <Hero
          title={{
            text: t("home:landing.restaurants:title"),
            className: "hero-title mb-0 md:text-left text-3xl md:text-5xl",
          }}
          text={{
            text: t("home:landing.restaurants:subtitle"),
            className: "section-subtitle text-center md:text-left uppercase",
          }}
          paragraphs={{
            list: t("home:landing.restaurants.text", { returnObjects: true }),
            className:
              "hero-text mb-4 text-center md:text-left text-sm md:text-base",
          }}
          styles={{
            heroContainer: "base-container pt-6 md:pt-12 md:pb-0",
            textContainer: "col-span-12 md:col-start-2 md:col-span-10 order-1",
            titleContainer: "col-span-12 md:col-start-2 md:col-span-10 order-2",
            paragraphsContainer:
              "col-span-12 md:col-start-2 md:col-span-10 order-3",
          }}
        />
        <TabsWithFilter filters={filters} classes={filterstyle} />
        <BlogsHome
          blogs={blogs}
          locale={locale}
          copy={home?.blogs}
        />
        <Instagram copy={home?.instagram} />
        <Newsletter gtmFormId="home-newsletter" locale={locale} />
        <Footer />
      </main>
    </>
  );
}

export async function getServerSideProps({ locale }) {
  const noIndex = process.env.ENV_NO_INDEX || false;

  const roomsQuery = QueryString.stringify(
    {
      locale,
      sort: "order:asc",
      populate: {
        room_category: {
          fields: ["id", "name"],
        },
        room_sliders: {
          fields: ["id", "image_alt"],
          populate: {
            image: {
              fields: ["url", "width", "height"],
            },
          },
          sort: "order:asc",
        },
        localizations: {
          fields: ["slug"],
          populate: {
            room_sliders: {
              fields: ["id", "image_alt"],
              populate: {
                image: {
                  fields: ["url", "width", "height"],
                },
              },
              sort: "order:asc",
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const rooms = await axiosGet(apiRooms, roomsQuery);

  const roomCategoriesQuery = QueryString.stringify(
    {
      locale,
      fields: ["id", "name", "icon_active_alt", "icon_inactive_alt"],
      populate: {
        icon_active: {
          fields: ["url", "width", "height"],
        },
        icon_inactive: {
          fields: ["url", "width", "height"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const roomCategories = await axiosGet(apiRoomCategories, roomCategoriesQuery);

  const promotionsQuery = QueryString.stringify(
    {
      locale,
      sort: "order:desc",
      fields: [
        "name",
        "is_home",
        "slug",
        "description",
        "thumbnail_alt, link",
        "price_from",
        'expiration_date',
        'start_date'
      ],
      populate: {
        thumbnail: {
          fields: ["url", "width", "height"],
        },
        localizations: {
          fields: ["slug"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const promotions = await axiosGet(apiPromotions, promotionsQuery);
  const validPromos = promotions.filter(
    (p) => {
      let initial_validity = false
      let final_validity = false
      initial_validity = !p.start_date && true || moment(p.start_date).format('YYYY-MM-DD,  HH:MM:SS')<=currentDate && true || false
      final_validity = !p.expiration_date && true || moment(p.expiration_date).format('YYYY-MM-DD,  HH:MM:SS')>currentDate && true || false
      return( initial_validity===true && final_validity===true && p.is_home===true)}
  );
  const blogsQuery = QueryString.stringify(
    {
      locale,
      sort: 'date:desc',
      fields: ["title", "slug", "date", "thumbnail_alt"],
      populate: {
        blog_category: {
          fields: ["name"],
        },
        thumbnail: {
          fields: ["url", "width", "height"],
        },
        localizations: {
          fields: ["slug"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const blogs = await axiosGet(apiBlogs, blogsQuery);

  const happeningsQuery = QueryString.stringify(
    {
      locale,
      fields: [
        "title",
        "subtitle",
        "slug",
        "start_time",
        "end_time",
        "zone",
        "happeningIn",
        "meta_title",
        "meta_description",
        "content",
        "short_description",
      ],
      populate: {
        thumbnail: {
          fields: ["url", "width", "height"],
        },
        localizations: {
          fields: ["slug"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const happenings = await axiosGet(apiHappenings, happeningsQuery);

  const slidersQuery = QueryString.stringify(
    {
      locale,
      filters: {
        landing: {
          $eq: "home",
        },
      },
      sort: "order:asc",
      fields: ["title", "subtitle", "url", "image_alt", "mobile_image_alt",'expiration_date', 'start_date'],
      populate: {
        image: {
          fields: ["url", "width", "height"],
        },
        mobile_image: {
          fields: ["url", "width", "height"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  const mainSliders = await axiosGet(apiSliders, slidersQuery);
  const validSliders = mainSliders.filter((p) => {
    let initial_validity = false;
    let final_validity = false;
    initial_validity =
      (!p.start_date && true) ||
      (moment(p.start_date).format('YYYY-MM-DD,  HH:MM:SS') <= currentDate &&
        true) ||
      false;
    final_validity =
      (!p.expiration_date && true) ||
      (moment(p.expiration_date).format('YYYY-MM-DD,  HH:MM:SS') >
        currentDate &&
        true) ||
      false;
    return initial_validity === true && final_validity === true;
  });
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        "common",
        "home",
        "restaurants",
        "bars",
        "routes",
      ])),
      mainSliders:validSliders,
      rooms,
      roomCategories,
      promotions:validPromos,
      blogs,
      happenings,
      locale,
      noIndex,
    },
  };
}
